import { DialogController } from 'aurelia-dialog';
import { autoinject, Container, observable } from 'aurelia-framework';
import { BootstrapValidationController } from '@resources/renderers/bootstrap-form-renderer';
import { ImpiloRepository, ImpiloDb } from 'services/repositories';
import { AutoCompleteController } from 'features/autocomplete';
import { Autocomplete } from 'features/autocomplete/autocomplete';
import { ValidationRules, Validator } from 'aurelia-validation';
import { ToastrService } from 'services/toastr-service';
import { AuthService } from 'services/auth-service';

@autoinject()
export class CloneDialog {

    model: {
        patientGuid: string,
        patientMedicationGuid: string,
        originalMedicationGuid: string,
        substitute: boolean,
        title: string,
        icd10?: ImpiloDb.Tables.ICD10,
        status?: ImpiloDb.Tables.PatientMedicationStatus,
        instructions?: string,
    };

    isWaiting = false;
    protected notification: any;

    @observable private selectedIcd10;
    private readonly icd10AutoCompleteController: AutoCompleteController;

    @observable private selectedMedication;
    private readonly medicationAutoCompleteController: AutoCompleteController;
    private medicationAutoComplete: Autocomplete;

    @observable private selectedStatus: Partial<ImpiloDb.Tables.PatientMedicationStatus> = null;
    private statuses: ImpiloDb.Tables.PatientMedicationStatus[];

    private instructions: string;

    private substitute = false;
    private cloneSchedule = false;

    constructor(private readonly repository: ImpiloRepository, private readonly validationController: BootstrapValidationController, private readonly validator: Validator,
        private readonly dialogController: DialogController, private readonly authService: AuthService) {

        this.selectedIcd10 = null;
        this.icd10AutoCompleteController = new AutoCompleteController((filter) => {
            return (this.repository as ImpiloRepository).filterICD10(filter, 1, 50);
        }, (suggestion) => {
            return `${suggestion.code} ${suggestion.name}`;
        });

        this.selectedMedication = null;
        this.medicationAutoCompleteController = new AutoCompleteController((filter) => {
            return (new ImpiloDb.Tables.Medication().fulltext(this.repository, filter, 1, 50));
        }, (suggestion) => {
            return suggestion.description.trim();
        });

        ValidationRules
            .ensure('selectedMedication').required().withMessage('Medication is required.')
            .on(this);

    }

    async selectedMedicationChanged(newValue, oldValue) {
        // console.log(newValue);
    }

    escape() {
        this.dialogController.cancel(this.model);
    }

    async activate(model) {
        this.model = { ...model };

        this.substitute = this.model.substitute;
        this.cloneSchedule = this.model.substitute;

        if (this.substitute) {
            // intentionally blank
        } else {
            this.statuses = await (this.repository as ImpiloRepository).lookups().statuses();
            this.selectedStatus = this.statuses[0];

            this.selectedIcd10 = this.model.icd10;
            this.instructions = this.model.instructions;
        }

        return this.validationController.validate();
    }

    deactivate() {
        //
    }

    async ok() {
        if (this.selectedMedication) {
            if (await this.validationController.validate()) {
                if (this.substitute || await this.isPatientMedicationAllowed()) {

                    const icd10Guid = this.selectedIcd10 && this.selectedIcd10.guid;
                    const statusGuid = this.selectedStatus && this.selectedStatus.guid;

                    var patientMedication = await this.repository.cloneMedicationSchedule(this.model.patientMedicationGuid, this.selectedMedication.guid, this.substitute, this.cloneSchedule, icd10Guid, statusGuid, this.instructions, this.authService.currentUser().user);

                    this.dialogController.ok(patientMedication);
                }
            }
        }
    }

    cancel() {
        this.dialogController.cancel();
    }

    async isPatientMedicationAllowed(): Promise<boolean> {

        const selectedMedication = this.selectedMedication;

        if (selectedMedication) {

            const response = await (this.repository as ImpiloRepository).isPatientMedicationAllowed(this.model.patientGuid, selectedMedication.guid);
            console.log(response);

            if (response) {
                if (!response.allow) {
                    this.warning(response.msg);
                }
                return response.allow;
            }
        }
        return false;

    }

    warning(msg) {
        const toastr = Container.instance.get(ToastrService);
        toastr.clear(this.notification);
        this.notification = toastr.warning(msg);
    }

}
