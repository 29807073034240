import { BindingEngine, Disposable } from 'aurelia-binding';
import { DialogController } from 'aurelia-dialog';
import { autoinject, computedFrom } from 'aurelia-framework';
import { BootstrapValidationController } from '@resources/renderers/bootstrap-form-renderer';
import { ImpiloRepository, ImpiloDb } from 'services/repositories';
import { ToastrService } from 'services/toastr-service';
import { Validator } from 'aurelia-validation';
import { sleep } from '@dts/scriptlib';
import { GenericEntity } from '@resources/utils/generic-entity';

@autoinject()
export class MedicationDialog extends GenericEntity {

    protected busyOk = false;
    protected notification: any;

    model: {
        description: string
    };
    entity: ImpiloDb.Tables.Medication;

    private codeSubscription: Disposable;
    isWaiting = false;

    constructor(repository: ImpiloRepository, private readonly validationController: BootstrapValidationController, validator: Validator, private readonly bindingEngine: BindingEngine, private readonly dialogController: DialogController, private readonly toastr: ToastrService) {
        super(repository, validator);

        this.entity = new ImpiloDb.Tables.Medication();
        this.entity.code = '';
        this.entity.isActive = true;
        this.entity.isDiscontinued = false;

        this.codeSubscription = this.bindingEngine.propertyObserver(this.entity, 'code')
            .subscribe(this.codeChanged.bind(this));
    }

    bind() {
        return this.validationController.validate();
    }

    codeMessage = '';
    async codeChanged(newValue, oldValue) {
        if (newValue !== null && newValue !== undefined) {
            this.isWaiting = true;
            this.codeMessage = '';

            let isUnique = false;
            const response = await (this.repository as ImpiloRepository).isMedicationCodeUnique(newValue);
            if (response.ok && response.results.length > 0) {
                isUnique = response.results[0].isUnique === 1;
            }
            if (!isUnique) {
                this.codeMessage = ` ${this.entity.code} is already assigned to ${response.results[0].description}`;
            }

            this.isWaiting = false;
        }
    }

    detached() {
        this.codeSubscription.dispose();
    }

    beforeMerge() {
        this.entity.name = this.entity.description;
        this.entity.created = new Date();
        this.entity.lastEdited = new Date();
        return super.beforeMerge();
    }

    afterMerge(): void {
        this.dialogController.ok(this.entity);
    }

    escape() {
        this.dialogController.cancel(this.model);
    }

    activate(model) {
        this.model = model;
        this.entity.description = this.model.description;
    }

    deactivate() {
        //
    }

    get displayName() {
        return this.entity.description;
    }

}


