import { BootstrapValidationController } from '@resources/renderers/bootstrap-form-renderer';
import { GenericEntity } from '@resources/utils/generic-entity';
import { autoinject, computedFrom, DOM } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { isGuid } from '@dts/scriptlib';
import { ImpiloDb, ImpiloRepository } from 'services/repositories';
import { patientChanged } from 'services/state/actions';
import { Validator } from 'aurelia-validation';

@autoinject()
export class Index extends GenericEntity {

    defaultLogo = 'easyblist_logo.jpg';

    treatmentHtml: string = "<div>Loading treatment plan...please wait</div>";

    private patients = [];

    constructor(repository: ImpiloRepository, private readonly controller: BootstrapValidationController, validator: Validator, private readonly router: Router) {
        super(repository, validator);
        this.entity = new ImpiloDb.Tables.Patient();
    }

    bind() {
        return this.controller.validate();
    }

    // abstract activate(params, routeConfig, navigationInstruction);
    async activate(params, routeConfig, navigationInstruction) {
        if (isGuid(params.guid)) {
            this.entity = await (this.repository as ImpiloRepository).getPatient(params.guid, false);
            const patient = this.entity as ImpiloDb.Tables.Patient;

            (this.repository as ImpiloRepository).getTreatmentPlan([patient.id], 2).then(f => this.treatmentHtml = f.results[0]);
        }
    }

    // abstract deactivate();
    deactivate() {
        //
    }

    // abstract escape();
    escape() {
        this.router.navigateToRoute('medications');
    }

    // abstract get displayName();
    @computedFrom('entity.firstName', 'entity.surname')
    get displayName() {
        const patient = this.entity as ImpiloDb.Tables.Patient;
        const displayName = `Treatment for ${patient.firstName} ${patient.surname}`;
        queueMicrotask(() => patientChanged(displayName, patient));
        return displayName;
    }
}
