import { isGuid } from '@dts/scriptlib';
import { TaskQueue, autoinject, Container, bindable } from 'aurelia-framework';
import { ImpiloDb, ImpiloRepository } from 'services/repositories';
import { ToastrService } from 'services/toastr-service';
import { Router } from 'aurelia-router';
import { ValidationRules, Validator, ValidateEvent } from 'aurelia-validation';
import { BootstrapValidationController } from '@resources/renderers/bootstrap-form-renderer';
import { AuthService } from 'services/auth-service';

@autoinject()
export class Index {

    protected notification: any;

    weekdays: boolean[][] = [
        [false, false, false, false, false, false, false],
        [false, false, false, false, false, false, false],
        [false, false, false, false, false, false, false],
        [false, false, false, false, false, false, false],
    ];

    all = false;

    week1 = false;
    week2 = false;
    week3 = false;
    week4 = false;

    day1 = false;
    day2 = false;
    day3 = false;
    day4 = false;
    day5 = false;
    day6 = false;
    day7 = false;

    @bindable week1day1 = false;
    @bindable week1day2 = false;
    @bindable week1day3 = false;
    @bindable week1day4 = false;
    @bindable week1day5 = false;
    @bindable week1day6 = false;
    @bindable week1day7 = false;

    @bindable week2day1 = false;
    @bindable week2day2 = false;
    @bindable week2day3 = false;
    @bindable week2day4 = false;
    @bindable week2day5 = false;
    @bindable week2day6 = false;
    @bindable week2day7 = false;

    @bindable week3day1 = false;
    @bindable week3day2 = false;
    @bindable week3day3 = false;
    @bindable week3day4 = false;
    @bindable week3day5 = false;
    @bindable week3day6 = false;
    @bindable week3day7 = false;

    @bindable week4day1 = false;
    @bindable week4day2 = false;
    @bindable week4day3 = false;
    @bindable week4day4 = false;
    @bindable week4day5 = false;
    @bindable week4day6 = false;
    @bindable week4day7 = false;

    sixAM = 0;
    eightAM = 0;
    twelvePM = 0;
    fivePM = 0;
    eightPM = 0;

    originalSchedule = {
        "sixAM": 0,
        "eightAM": 0,
        "twelvePM": 0,
        "fivePM": 0,
        "eightPM": 0,
        "week1day1": false,
        "week1day2": false,
        "week1day3": false,
        "week1day4": false,
        "week1day5": false,
        "week1day6": false,
        "week1day7": false,
        "week2day1": false,
        "week2day2": false,
        "week2day3": false,
        "week2day4": false,
        "week2day5": false,
        "week2day6": false,
        "week2day7": false,
        "week3day1": false,
        "week3day2": false,
        "week3day3": false,
        "week3day4": false,
        "week3day5": false,
        "week3day6": false,
        "week3day7": false,
        "week4day1": false,
        "week4day2": false,
        "week4day3": false,
        "week4day4": false,
        "week4day5": false,
        "week4day6": false,
        "week4day7": false
    };

    protected entity: ImpiloDb.Tables.PatientMedication;

    constructor(private readonly repository: ImpiloRepository, private readonly taskQueue: TaskQueue, private readonly router: Router, private readonly controller: BootstrapValidationController, private readonly authService: AuthService) {
        ValidationRules
            .ensure('sixAM').between(-1, 1000).withMessage('Valid 1 through 999')
            .ensure('eightAM').between(-1, 1000).withMessage('Valid 1 through 999')
            .ensure('twelvePM').between(-1, 1000).withMessage('Valid 1 through 999')
            .ensure('fivePM').between(-1, 1000).withMessage('Valid 1 through 999')
            .ensure('eightPM').between(-1, 1000).withMessage('Valid 1 through 999')
            .on(this);
    }

    bind() {
        return this.controller.validate();
    }

    async activate(params, routeConfig, navigationInstruction) {

        if (isGuid(params.guid)) {
            this.entity = ImpiloDb.Tables.PatientMedication.map({});
            this.entity = await this.entity.get(this.repository, params.guid) as ImpiloDb.Tables.PatientMedication;

            if (this.entity.simpleSchedule) {
                this.updateOriginalSchedule();
            } else {
                this.originalSchedule = {
                    "sixAM": 0,
                    "eightAM": 0,
                    "twelvePM": 0,
                    "fivePM": 0,
                    "eightPM": 0,
                    "week1day1": false,
                    "week1day2": false,
                    "week1day3": false,
                    "week1day4": false,
                    "week1day5": false,
                    "week1day6": false,
                    "week1day7": false,
                    "week2day1": false,
                    "week2day2": false,
                    "week2day3": false,
                    "week2day4": false,
                    "week2day5": false,
                    "week2day6": false,
                    "week2day7": false,
                    "week3day1": false,
                    "week3day2": false,
                    "week3day3": false,
                    "week3day4": false,
                    "week3day5": false,
                    "week3day6": false,
                    "week3day7": false,
                    "week4day1": false,
                    "week4day2": false,
                    "week4day3": false,
                    "week4day4": false,
                    "week4day5": false,
                    "week4day6": false,
                    "week4day7": false
                };

                this.clear();
                this.sixAM = 0;
                this.eightAM = 0;
                this.twelvePM = 0;
                this.fivePM = 0;
                this.eightPM = 0;
            }
        }
    }

    get isSimpleSchedule() {
        if (this.entity) {
            return !this.entity?.isComprehensiveSchedule;
        }
        return false;
    }

    get isComplexSchedule() {
        if (this.entity) {
            return this.entity.isComprehensiveSchedule;
        }
        return false;
    }

    get isNew() {
        if (this.entity) {
            return this.entity.isNew();
        }
        return true;
    }

    updateOriginalSchedule() {
        const simpleSchedule = this.entity.simpleSchedule.split('|');

        const pairs = simpleSchedule[0].split(';');

        for (const week of [1, 2, 3, 4]) {
            for (const day of [1, 2, 3, 4, 5, 6, 7]) {
                const varname = `week${week}day${day}`;
                this[varname] = false;
                this.originalSchedule[varname] = false;
            }
        }

        if (pairs.length > 0) {
            for (const pair of pairs) {
                const wd = pair.split(',');
                const varname = `week${wd[0]}day${wd[1]}`;
                this[varname] = true;
                this.originalSchedule[varname] = true;
            }
        }

        this.sixAM = +simpleSchedule[1];
        this.eightAM = +simpleSchedule[2];
        this.twelvePM = +simpleSchedule[3];
        this.fivePM = +simpleSchedule[4];
        this.eightPM = +simpleSchedule[5];

        this.originalSchedule.sixAM = this.sixAM;
        this.originalSchedule.eightAM = this.eightAM;
        this.originalSchedule.twelvePM = this.twelvePM;
        this.originalSchedule.fivePM = this.fivePM;
        this.originalSchedule.eightPM = this.eightPM;
        this.fixAll();
    }

    loadOriginalSchedule() {
        this.sixAM = this.originalSchedule.sixAM;
        this.eightAM = this.originalSchedule.eightAM;
        this.twelvePM = this.originalSchedule.twelvePM;
        this.fivePM = this.originalSchedule.fivePM;
        this.eightPM = this.originalSchedule.eightPM;

        for (const week of [1, 2, 3, 4]) {
            for (const day of [1, 2, 3, 4, 5, 6, 7]) {
                const varname = `week${week}day${day}`;
                this[varname] = this.originalSchedule[varname];
            }
        }
        this.fixAll();
    }

    async reset() {

        this.entity = await this.repository.mergeMedicationSchedule(this.entity.guid, 'reset', 0, 0, 0, 0, 0, this.authService.currentUser().user);
        // this.isSimpleSchedule = !this.entity.isComprehensiveSchedule;
        if (this.entity.simpleSchedule) {
            this.updateOriginalSchedule();
        }

    }

    async revert() {
        this.entity = await this.repository.mergeMedicationSchedule(this.entity.guid, 'revert', 0, 0, 0, 0, 0, this.authService.currentUser().user);
        // this.isSimpleSchedule = !this.entity.isComprehensiveSchedule;
        if (this.entity.simpleSchedule) {
            this.updateOriginalSchedule();
        }
    }

    async apply(): Promise<boolean> {

        const validate = await this.controller.validate();

        if (!validate.valid || this.sixAM + this.eightAM + this.twelvePM + this.fivePM + this.eightPM == 0) {
            this.warning('Please supply dosages before saving the schedule');
            return false;
        }

        let pairs = [];

        for (const week of [1, 2, 3, 4]) {
            for (const day of [1, 2, 3, 4, 5, 6, 7]) {
                const varname = `week${week}day${day}`;
                if (this[varname] == true) {
                    pairs.push(`${week},${day}`);
                }
            }
        }

        if (pairs.length == 0) {
            this.warning('Please supply daily schedules before saving the schedule');
            return false;
        }

        const weekdays = pairs.join(';');

        this.entity = await this.repository.mergeMedicationSchedule(this.entity.guid, weekdays, +this.sixAM, +this.eightAM, +this.twelvePM, +this.fivePM, +this.eightPM, this.authService.currentUser().user);
        this.updateOriginalSchedule();

        return true;

    }

    cancel() {
        this.loadOriginalSchedule();
    }

    async ok() {
        if (await this.apply()) {
            this.router.navigateToRoute('medications');
        }
    }


    get isDirty(): boolean {
        const notDirty =
            this.sixAM == this.originalSchedule.sixAM &&
            this.eightAM == this.originalSchedule.eightAM &&
            this.twelvePM == this.originalSchedule.twelvePM &&
            this.fivePM == this.originalSchedule.fivePM &&
            this.eightPM == this.originalSchedule.eightPM &&
            this.week1day1 == this.originalSchedule.week1day1 &&
            this.week1day2 == this.originalSchedule.week1day2 &&
            this.week1day3 == this.originalSchedule.week1day3 &&
            this.week1day4 == this.originalSchedule.week1day4 &&
            this.week1day5 == this.originalSchedule.week1day5 &&
            this.week1day6 == this.originalSchedule.week1day6 &&
            this.week1day7 == this.originalSchedule.week1day7 &&
            this.week2day1 == this.originalSchedule.week2day1 &&
            this.week2day2 == this.originalSchedule.week2day2 &&
            this.week2day3 == this.originalSchedule.week2day3 &&
            this.week2day4 == this.originalSchedule.week2day4 &&
            this.week2day5 == this.originalSchedule.week2day5 &&
            this.week2day6 == this.originalSchedule.week2day6 &&
            this.week2day7 == this.originalSchedule.week2day7 &&
            this.week3day1 == this.originalSchedule.week3day1 &&
            this.week3day2 == this.originalSchedule.week3day2 &&
            this.week3day3 == this.originalSchedule.week3day3 &&
            this.week3day4 == this.originalSchedule.week3day4 &&
            this.week3day5 == this.originalSchedule.week3day5 &&
            this.week3day6 == this.originalSchedule.week3day6 &&
            this.week3day7 == this.originalSchedule.week3day7 &&
            this.week4day1 == this.originalSchedule.week4day1 &&
            this.week4day2 == this.originalSchedule.week4day2 &&
            this.week4day3 == this.originalSchedule.week4day3 &&
            this.week4day4 == this.originalSchedule.week4day4 &&
            this.week4day5 == this.originalSchedule.week4day5 &&
            this.week4day6 == this.originalSchedule.week4day6 &&
            this.week4day7 == this.originalSchedule.week4day7;

        return !notDirty;
    }

    propertyChanged(name, newValue, oldValue) {
        // console.log(name, newValue, oldValue );

        const week = name.substring(4, 5);
        const day = name.substring(8, 9);

        this.weekdays[week - 1][day - 1] = newValue;
    }

    allClicked() {
        this.all = !this.all;

        this.setWeek(1, this.all);
        this.setWeek(2, this.all);
        this.setWeek(3, this.all);
        this.setWeek(4, this.all);
        this.fixAll();
        return true;
    }

    dayClicked(day: number) {
        let value = false;
        switch (day) {
            case 1:
                value = !this.day1;
                this.day1 = value;
                break;

            case 2:
                value = !this.day2;
                this.day2 = value;
                break;

            case 3:
                value = !this.day3;
                this.day3 = value;
                break;

            case 4:
                value = !this.day4;
                this.day4 = value;
                break;

            case 5:
                value = !this.day5;
                this.day5 = value;
                break;

            case 6:
                value = !this.day6;
                this.day6 = value;
                break;

            case 7:
                value = !this.day7;
                this.day7 = value;
                break;

        }
        this.setDay(day, value);

        this.fixAll();


        return true;
    }

    weekClicked(week: number) {
        let value = false;
        switch (week) {
            case 1:
                value = !this.week1;
                this.week1 = value;
                break;

            case 2:
                value = !this.week2;
                this.week2 = value;
                break;

            case 3:
                value = !this.week3;
                this.week3 = value;
                break;

            case 4:
                value = !this.week4;
                this.week4 = value;
                break;
        }
        this.setWeek(week, value);
        this.fixAll();
        return true;
    }

    weekdayClickedOuter(event: MouseEvent, week: number, day: number) {
        const varname = `week${week}day${day}`;

        this[varname] = !this[varname];
        this.fixAll();
        event.stopPropagation();
    }

    weekdayClicked(event: MouseEvent, week: number, day: number) {
        const varname = `week${week}day${day}`;
        this[varname] = !this[varname];
        this.fixAll();
        event.stopPropagation();
        return true;
    }

    setAll(value: boolean) {
        for (let i = 0; i < 4; i++) {
            for (let j = 0; j < 7; j++) {
                this.weekdays[i][j] = value;
            }
        }
    }

    setDay(day: number, value: boolean) {
        for (let i = 0; i < 4; i++) {
            this.weekdays[i][day] = value;
        }

        switch (day) {
            case 1:
                this.week1day1 = value;
                this.week2day1 = value;
                this.week3day1 = value;
                this.week4day1 = value;
                break;

            case 2:
                this.week1day2 = value;
                this.week2day2 = value;
                this.week3day2 = value;
                this.week4day2 = value;
                break;

            case 3:
                this.week1day3 = value;
                this.week2day3 = value;
                this.week3day3 = value;
                this.week4day3 = value;
                break;

            case 4:
                this.week1day4 = value;
                this.week2day4 = value;
                this.week3day4 = value;
                this.week4day4 = value;
                break;

            case 5:
                this.week1day5 = value;
                this.week2day5 = value;
                this.week3day5 = value;
                this.week4day5 = value;
                break;

            case 6:
                this.week1day6 = value;
                this.week2day6 = value;
                this.week3day6 = value;
                this.week4day6 = value;
                break;

            case 7:
                this.week1day7 = value;
                this.week2day7 = value;
                this.week3day7 = value;
                this.week4day7 = value;
                break;
        }
    }

    setWeek(week: number, value: boolean) {
        for (let j = 0; j < 7; j++) {
            this.weekdays[week - 1][j] = value;
        }

        switch (week) {
            case 1:
                this.week1day1 = value;
                this.week1day2 = value;
                this.week1day3 = value;
                this.week1day4 = value;
                this.week1day5 = value;
                this.week1day6 = value;
                this.week1day7 = value;
                break;

            case 2:
                this.week2day1 = value;
                this.week2day2 = value;
                this.week2day3 = value;
                this.week2day4 = value;
                this.week2day5 = value;
                this.week2day6 = value;
                this.week2day7 = value;
                break;

            case 3:
                this.week3day1 = value;
                this.week3day2 = value;
                this.week3day3 = value;
                this.week3day4 = value;
                this.week3day5 = value;
                this.week3day6 = value;
                this.week3day7 = value;
                break;

            case 4:
                this.week4day1 = value;
                this.week4day2 = value;
                this.week4day3 = value;
                this.week4day4 = value;
                this.week4day5 = value;
                this.week4day6 = value;
                this.week4day7 = value;
                break;
        }
    }

    presetAlternateMonday() {
        this.clear();
        this.week1day1 = true;
        this.week1day3 = true;
        this.week1day5 = true;
        this.week1day7 = true;

        this.week2day2 = true;
        this.week2day4 = true;
        this.week2day6 = true;

        this.week3day1 = true;
        this.week3day3 = true;
        this.week3day5 = true;
        this.week3day7 = true;

        this.week4day2 = true;
        this.week4day4 = true;
        this.week4day6 = true;

        this.fixAll();
    }

    presetAlternateTuesday() {
        this.clear();

        this.week1day2 = true;
        this.week1day4 = true;
        this.week1day6 = true;

        this.week2day1 = true;
        this.week2day3 = true;
        this.week2day5 = true;
        this.week2day7 = true;

        this.week3day2 = true;
        this.week3day4 = true;
        this.week3day6 = true;

        this.week4day1 = true;
        this.week4day3 = true;
        this.week4day5 = true;
        this.week4day7 = true;

        this.fixAll();
    }

    presetMonToFri() {
        this.setDay(1, true);
        this.setDay(2, true);
        this.setDay(3, true);
        this.setDay(4, true);
        this.setDay(5, true);
        this.setDay(6, false);
        this.setDay(7, false);
        this.fixAll();
    }

    inverse() {
        for (const week of [1, 2, 3, 4]) {
            for (const day of [1, 2, 3, 4, 5, 6, 7]) {
                const varname = `week${week}day${day}`;
                this[varname] = !this[varname];
            }
        }
        this.fixAll();
    }

    clear() {
        this.setWeek(1, false);
        this.setWeek(2, false);
        this.setWeek(3, false);
        this.setWeek(4, false);
        this.fixAll();
    }

    fixAll() {
        this.taskQueue.queueTask(() => {
            this.week1 = this.week1day1 && this.week1day2 && this.week1day3 && this.week1day4 && this.week1day5 && this.week1day6 && this.week1day7;
            this.week2 = this.week2day1 && this.week2day2 && this.week2day3 && this.week2day4 && this.week2day5 && this.week2day6 && this.week2day7;
            this.week3 = this.week3day1 && this.week3day2 && this.week3day3 && this.week3day4 && this.week3day5 && this.week3day6 && this.week3day7;
            this.week4 = this.week4day1 && this.week4day2 && this.week4day3 && this.week4day4 && this.week4day5 && this.week4day6 && this.week4day7;

            this.day1 = this.week1day1 && this.week2day1 && this.week3day1 && this.week4day1;
            this.day2 = this.week1day2 && this.week2day2 && this.week3day2 && this.week4day2;
            this.day3 = this.week1day3 && this.week2day3 && this.week3day3 && this.week4day3;
            this.day4 = this.week1day4 && this.week2day4 && this.week3day4 && this.week4day4;
            this.day5 = this.week1day5 && this.week2day5 && this.week3day5 && this.week4day5;
            this.day6 = this.week1day6 && this.week2day6 && this.week3day6 && this.week4day6;
            this.day7 = this.week1day7 && this.week2day7 && this.week3day7 && this.week4day7;

            this.all = this.week1 && this.week2 && this.week3 && this.week4 && this.day1 && this.day2 && this.day3 && this.day4 && this.day5 && this.day6 && this.day7;
        });
    }

    async canDeactivate(): Promise<boolean> {
        if (this.isDirty) {
            if (await this.apply()) {
                return true;
            }
            // this.warning('Please save or undo the changes.');
            return false;
        }
        return true;
    }

    warning(msg) {
        const toastr = Container.instance.get(ToastrService);
        toastr.clear(this.notification);
        this.notification = toastr.warning(msg);
    }

}

function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
}
