import { BootstrapValidationController } from '@resources/renderers/bootstrap-form-renderer';
import { DialogService } from 'aurelia-dialog';
import { GenericEntity } from '@resources/utils/generic-entity';
import { autoinject, computedFrom, observable } from 'aurelia-framework';
import { PLATFORM } from 'aurelia-pal';
import { Router, RouterConfiguration } from 'aurelia-router';
import { ValidationRules, Validator, ValidateEvent } from 'aurelia-validation';
import { AutoCompleteController } from 'features/autocomplete';
import { isGuid, isNullOrUndefined } from '@dts/scriptlib';
import { ImpiloDb, ImpiloRepository } from 'services/impilo-repository';
import { getStateProperty } from 'services/state/actions';
import { MedicationDialog } from './quick/medication';
import { DoctorDialog } from './quick/doctor';
import { Autocomplete } from 'features/autocomplete/autocomplete';
import { CloneDialog } from './clone/clone';
import { AuthService } from 'services/auth-service';

@autoinject()
export class Index extends GenericEntity {

    debug = {};
    private router: Router;

    @observable private selectedPatient: ImpiloDb.Tables.Patient;

    @observable private selectedMedication;
    private readonly medicationAutoCompleteController: AutoCompleteController;
    private medicationAutoComplete: Autocomplete;

    @observable private selectedIcd10;
    private readonly icd10AutoCompleteController: AutoCompleteController;

    @observable private selectedDoctor;
    private readonly doctorAutoCompleteController: AutoCompleteController;
    private doctorAutoComplete: Autocomplete;

    @observable private selectedStatus: Partial<ImpiloDb.Tables.PatientMedicationStatus> = null;
    private statuses: ImpiloDb.Tables.PatientMedicationStatus[];

    @observable private dateWritten: Date;
    @observable private repeats: number;
    @observable private scriptValidTo: Date;
    @observable private refillDate: Date;

    dateWrittenOptions = {
        maxDate: 'today',
        allowInput: true,
        defaultDate: 'today'
    }

    scriptValidToOptions = {
        allowInput: true
    }

    isAttached = false;
    focusDoctor = false;
    focusMedicine = false;


    constructor(repository: ImpiloRepository, private readonly controller: BootstrapValidationController, validator: Validator, private readonly dialogService: DialogService, private readonly authService: AuthService) {
        super(repository, validator);
        this.entity = ImpiloDb.Tables.PatientMedication.map({});
        const entity = this.entity as ImpiloDb.Tables.PatientMedication;
        entity.created = new Date();
        entity.lastEdited = new Date();
        entity.isComprehensiveSchedule = false;
        entity.userGuid = this.authService.currentUser().user;

        this.selectedMedication = null;
        this.medicationAutoCompleteController = new AutoCompleteController((filter) => {
            return (new ImpiloDb.Tables.Medication().fulltext(this.repository, filter, 1, 50));
        }, (suggestion) => {
            return suggestion.description.trim();
        }/*, async () => {
            const selectedPatient = await this.getSelectedPatient();
            if (selectedPatient) {
                // console.log('getPreloadPharmacyPatient', selectedPatient.guid);
                return (this.repository as ImpiloRepository).getPreloadPharmacyPatient('Medication', selectedPatient.guid);
            }
        }*/);

        this.selectedIcd10 = null;
        this.icd10AutoCompleteController = new AutoCompleteController((filter) => {
            return (this.repository as ImpiloRepository).filterICD10(filter, 1, 50);
            // return (new ImpiloDb.Tables.ICD10().fulltext(this.repository, filter, 1, 50));
        }, (suggestion) => {
            return `${suggestion.code} ${suggestion.name}`;
        }/*, () => {
            return (this.repository as ImpiloRepository).getPreloadPharmacy('ICD10');
        }*/);

        this.selectedDoctor = null;
        this.doctorAutoCompleteController = new AutoCompleteController((filter) => {
            return (this.repository as ImpiloRepository).filterDoctor(filter, 1, 50);
            // return (new ImpiloDb.Tables.Doctor().fulltext(this.repository, filter, 1, 50));
        }, (suggestion) => {
            return suggestion.name;
        }/*, () => {
            return (this.repository as ImpiloRepository).getPreloadPharmacy('Doctor');
        }*/);

        ValidationRules
            .ensure('selectedMedication').required().withMessage('Medication is required.')
            .ensure('selectedIcd10').required().withMessage('Diagnosis code is required.')
            .ensure('selectedDoctor').required().withMessage('Doctor is required.')
            .ensure('selectedStatus').required().withMessage('Medication Status is required.')
            .ensure('repeats').required().between(-1, 1000)
            .ensure('dateWritten').required().satisfiesRule('date')
            .ensure('scriptValidTo').required().satisfiesRule('date')
            .ensure('refillDate').satisfiesRule('date')
            .on(this);

    }

    bind() {

        return this.controller.validate();
    }

    configureRouter(config: RouterConfiguration, router: Router) {
        config.title = '';
        config.map([
            { route: '', redirect: 'schedule' },
            { route: 'schedule', name: 'schedule', moduleId: PLATFORM.moduleName('./schedule/index'), title: 'Schedule', nav: true },
        ]);
        this.router = router;
    }

    async showQuickAddMedication() {
        if (!this.dialogService.hasOpenDialog) {
            const result = await this.dialogService.open({
                viewModel: MedicationDialog,
                model: { description: this.medicationAutoCompleteController.searchText },
                lock: true,
                keyboard: false,
                startingZIndex: 99999,
                restoreFocus: (lastActiveElement: HTMLElement) => {
                    this.medicationAutoComplete.focusMe();
                }
            }).whenClosed((value) => {
                if (!value.wasCancelled) {
                    const item = value?.output;
                    if (item) {
                        this.medicationAutoComplete.addToCache(item.name, [{
                            guid: item.guid,
                            id: item.id,
                            description: item.description
                        }]);
                    }
                }
            });

        }
    }

    async showQuickAddDoctor() {
        if (!this.dialogService.hasOpenDialog) {
            const result = await this.dialogService.open({
                viewModel: DoctorDialog,
                model: { name: this.doctorAutoCompleteController.searchText },
                lock: true,
                keyboard: false,
                startingZIndex: 99999,
                restoreFocus: (lastActiveElement: HTMLElement) => {
                    this.doctorAutoComplete.focusMe();
                }
            }).whenClosed((value) => {
                if (!value.wasCancelled) {
                    const item = value?.output;
                    if (item) {
                        this.doctorAutoComplete.addToCache(item.name, [{
                            guid: item.guid,
                            id: item.id,
                            name: item.name,
                            practice: item.practice,
                        }]);
                    }
                }
            });

        }
    }

    async showCloneMedication() {

        await this.ok(false);
        if (await this.canDeactivate()) {

            if (!this.dialogService.hasOpenDialog) {
                const entity = this.entity as ImpiloDb.Tables.PatientMedication;

                const result = await this.dialogService.open({
                    viewModel: CloneDialog,
                    model: {
                        patientGuid: entity.patientGuid,
                        patientMedicationGuid: entity.guid,
                        originalMedicationGuid: entity.medicationGuid,
                        substitute: false,
                        title: 'Add New Medication',
                        icd10: { ...entity.iCD10 },
                        status: { ...entity.patientMedicationStatus },
                        instructions: entity.instructions
                    },
                    lock: true,
                    keyboard: true,
                    startingZIndex: 99999,
                    restoreFocus: (lastActiveElement: HTMLElement) => {

                    }
                }).whenClosed((value) => {
                    if (!value.wasCancelled) {
                        const item = value?.output;
                        if (item) {
                            this.router.navigateToRoute('medication', { guid: item.guid }, { trigger: true, replace: true });
                            queueMicrotask(() => this.focusDoctor = true);


                        }
                    }
                });
            }
        }
    }

    async showSubstituteMedication() {

        await this.ok(false);
        if (await this.canDeactivate()) {

            if (!this.dialogService.hasOpenDialog) {
                const entity = this.entity as ImpiloDb.Tables.PatientMedication;

                const result = await this.dialogService.open({
                    viewModel: CloneDialog,
                    model: {
                        patientGuid: entity.patientGuid,
                        patientMedicationGuid: entity.guid,
                        originalMedicationGuid: entity.medicationGuid,
                        substitute: true,
                        title: 'Change Medication'
                    },
                    lock: true,
                    keyboard: true,
                    startingZIndex: 99999,
                    restoreFocus: (lastActiveElement: HTMLElement) => {

                    }
                }).whenClosed((value) => {
                    if (!value.wasCancelled) {
                        const item = value?.output;
                        if (item) {
                            this.router.navigateToRoute('medication', { guid: item.guid }, { trigger: true, replace: true });
                            queueMicrotask(() => this.focusDoctor = true);


                        }
                    }
                });
            }
        }
    }

    // abstract get displayName();
    @computedFrom('entity.medication.description')
    get displayName() {
        const patientMedication = this.entity as ImpiloDb.Tables.PatientMedication;
        return `${patientMedication.medication?.description ?? 'New Medication'}`;
    }

    get isNew() {
        return this.entity?.isNew() ?? true;
    }

    // abstract activate(params, routeConfig, navigationInstruction);
    async activate(params, routeConfig, navigationInstruction) {
        this.statuses = await (this.repository as ImpiloRepository).lookups().statuses();

        if (isGuid(params.guid)) {
            this.entity = await this.entity.get(this.repository, params.guid) as ImpiloDb.Tables.PatientMedication;
            const entity = this.entity as ImpiloDb.Tables.PatientMedication;

            this.selectedPatient = entity.patient;
            this.selectedMedication = entity.medication;
            this.selectedIcd10 = entity.iCD10;
            this.selectedDoctor = entity.doctor;
            this.selectedStatus = entity.patientMedicationStatus;
            this.dateWritten = entity.dateWritten;
            this.repeats = entity.repeats;
            this.scriptValidTo = entity.scriptValidTo;
            this.refillDate = entity.refillDate;

        } else {
            this.selectedStatus = this.statuses[0];
            queueMicrotask(() => this.focusDoctor = true);
        }

        this.isAttached = true;
    }

    // abstract deactivate();
    deactivate() {
        //
    }

    // abstract escape();
    escape() {
        if (!this.dialogService.hasOpenDialog) {
            this.router.navigateToRoute('medications');
        }
    }

    async selectedMedicationChanged(newValue, oldValue) {
        if (this.isAttached && this.entity !== undefined) {
            (this.entity as ImpiloDb.Tables.PatientMedication).medication = newValue;
        }
    }

    selectedIcd10Changed(newValue, oldValue) {
        if (this.isAttached && this.entity !== undefined) {
            (this.entity as ImpiloDb.Tables.PatientMedication).iCD10 = newValue;
        }
    }

    selectedDoctorChanged(newValue, oldValue) {
        if (this.isAttached && this.entity !== undefined) {
            (this.entity as ImpiloDb.Tables.PatientMedication).doctor = newValue;
        }
    }

    selectedPatientChanged(newValue, oldValue) {
        if (this.isAttached && this.entity !== undefined) {
            (this.entity as ImpiloDb.Tables.PatientMedication).patient = newValue;
        }
    }

    selectedStatusChanged(newValue, oldValue) {
        if (this.entity !== undefined) {
            (this.entity as ImpiloDb.Tables.PatientMedication).patientMedicationStatus = newValue;
        }
    }

    dateWrittenChanged(newValue, oldValue) {
        if (this.isAttached && this.entity !== undefined) {

            const entity = (this.entity as ImpiloDb.Tables.PatientMedication);

            entity.dateWritten = newValue;

            const validFromDate = new Date(entity.dateWritten);
            const validToDate = new Date(validFromDate.setMonth(validFromDate.getMonth() + entity.repeats ?? 0));

            entity.scriptValidTo = validToDate;


            if (isDate(entity.refillDate)) {
                entity.refillDate = validToDate;
            } else {
                entity.refillDate = null;
            }

            this.scriptValidTo = entity.scriptValidTo;
            this.refillDate = entity.refillDate;
        }
    }

    repeatsChanged(newValue, oldValue) {
        if (this.isAttached && this.entity !== undefined) {

            const entity = (this.entity as ImpiloDb.Tables.PatientMedication);
            entity.repeats = +newValue;

            const validFromDate = new Date(entity.dateWritten);
            const validToDate = new Date(validFromDate.setMonth(validFromDate.getMonth() + entity.repeats));

            entity.scriptValidTo = validToDate;

            if (isDate(entity.refillDate)) {
                entity.refillDate = validToDate;
            } else {
                entity.refillDate = null;
            }

            this.scriptValidTo = entity.scriptValidTo;
            this.refillDate = entity.refillDate;
        }
    }

    scriptValidToChanged(newValue, oldValue) {
        if (this.isAttached && this.entity !== undefined) {
            const entity = (this.entity as ImpiloDb.Tables.PatientMedication);
            entity.scriptValidTo = newValue;
        }
    }

    refillDateChanged(newValue, oldValue) {
        if (this.isAttached && this.entity !== undefined) {
            const entity = (this.entity as ImpiloDb.Tables.PatientMedication);
            entity.refillDate = newValue;
        }
    }

    async apply() {

        if (!this.dialogService.hasOpenDialog) {
            const entity = (this.entity as ImpiloDb.Tables.PatientMedication);
            entity.userGuid = this.authService.currentUser().user;

            const isAllowed = await this.isPatientMedicationAllowed();

            if (isAllowed) {
                return super.apply();
            } else {
                this.selectedMedication = null;
                queueMicrotask(() => this.focusMedicine = true);
            }
        }
    }

    busyOkInternal = false;
    async ok(escape = true) {

        if (!(this.dialogService.hasOpenDialog || this.busyOkInternal)) {
            this.busyOkInternal = true;
            const entity = (this.entity as ImpiloDb.Tables.PatientMedication);
            entity.userGuid = this.authService.currentUser().user;

            this.debug = JSON.stringify(this.entity, null, 2);

            const isAllowed = await this.isPatientMedicationAllowed();

            this.busyOkInternal = false;
            if (isAllowed) {
                return super.ok(escape);
            } else {
                this.selectedMedication = null;
                queueMicrotask(() => this.focusMedicine = true);
            }
        }
    }

    async isPatientMedicationAllowed(): Promise<boolean> {

        if (this.isNew) {
            const selectedPatient = await this.getSelectedPatient();
            const selectedMedication = this.selectedMedication;

            if (selectedPatient && selectedMedication) {

                const response = await (this.repository as ImpiloRepository).isPatientMedicationAllowed(selectedPatient.guid, selectedMedication.guid);

                if (!response.allow) {
                    this.warning(response.msg);
                }

                return response.allow;
            }
            return false;
        }

        return true;

    }

    async getSelectedPatient(): Promise<ImpiloDb.Tables.Patient> {
        if (this.entity !== undefined && isNullOrUndefined(this.selectedPatient)) {
            const current: any = await getStateProperty('plugins', 'blister', 'currentPatient');
            return current.patient as ImpiloDb.Tables.Patient;
        }
        return this.selectedPatient;
    }

    async beforeMerge(): Promise<boolean> {
        if (this.entity !== undefined && isNullOrUndefined(this.selectedPatient)) {
            const current: any = await getStateProperty('plugins', 'blister', 'currentPatient');
            this.selectedPatient = current.patient as ImpiloDb.Tables.Patient;
        }

        return this.selectedPatient !== null;
    }

    afterMerge() {
        const path = (this.entity as ImpiloDb.Tables.PatientMedication).isComprehensiveSchedule ? 'complex' : 'simplex';
        const route = `${this.router.generate('medication', { guid: this.entity.guid })}/schedule/` + path;
        this.router.navigate(route);
    }

    private readonly idMatcher = (a, b) => {
        if (a && b) {
            return a.id === b.id;
        }

        return false;
    }
}

function isDate(s) {
    if (isNaN(s) && !isNaN(Date.parse(s)))
        return true;
    else return false;
}
