import { BootstrapValidationController } from '@resources/renderers/bootstrap-form-renderer';
import { CustomFloatEditor, CustomFloatValidator } from '@resources/slickgrid/index';
import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { AureliaGridInstance, Editors, FieldType, Formatters, GridOption, OnEventArgs } from 'aurelia-slickgrid';
import { Store } from 'aurelia-store';
import { ImpiloDb, ResultPager } from 'plugins/data-models';
import { isGuid } from '@dts/scriptlib';
import { ImpiloRepository } from 'services/impilo-repository';
import { IState } from 'services/state/state';
import { ToastrService } from 'services/toastr-service';
import { AuthService } from 'services/auth-service';

declare var Slick: any;

@autoinject()
export class Index {

    gridOptions: GridOption;
    gridDataset = [];
    gridColumns = [];
    aureliaGrid: AureliaGridInstance;

    private patientMedication: Partial<ImpiloDb.Tables.PatientMedication> = new ImpiloDb.Tables.PatientMedication();
    private readonly pager: ResultPager;
    private readonly commandQueue = [];

    constructor(private readonly repository: ImpiloRepository, private readonly router: Router, private readonly store: Store<IState>, private readonly toastr: ToastrService, private readonly authService: AuthService) {
        this.defineGrid();

        this.pager = new ResultPager(async (page: number, size: number) => {

            const response = await this.repository.getMedicationSchedulePivot(this.patientMedication.guid);

            // await this.store.dispatch(patientSelectedRowAction, undefined);
            // this.setSelectedRow(undefined);
            let id = 0;
            this.gridDataset = response.results.map((entity) => {
                entity['id'] = ++id;
                return entity;
            });

            this.setGrouping();

            return response;
        });
    }

    get isNew() {
        if (this.patientMedication) {
            return this.patientMedication.isNew();
        }
        return true;
    }

    async activate(params, routeConfig, navigationInstruction) {
        if (isGuid(params.guid)) {
            this.patientMedication = await this.patientMedication.get(this.repository, params.guid) as ImpiloDb.Tables.PatientMedication;
            await this.pager.gotoFirst();
        }
    }

    aureliaGridCreated(aureliaGrid: AureliaGridInstance) {
        this.aureliaGrid = aureliaGrid;
        this.aureliaGrid.dataView.syncGridSelection(this.aureliaGrid.slickGrid, true);

        this.aureliaGrid.dataView.onRowsChanged.subscribe((e, args) => {
            // this.setSelectedRow(this.statePatients.fulltext.selectedRow);
        });

        this.setGrouping();
    }

    handleRowSelection(event, args) {
        // return this.store.dispatch(doctorSelectedRowAction, args.rows[0]);
    }

    setSelectedRow(row: number) {

        this.aureliaGrid.gridService.setSelectedRows([]);
        this.aureliaGrid.gridStateService.resetRowSelectionWhenRequired();

        if (row >= 0) {
            this.aureliaGrid.gridService.setSelectedRow(row);
            this.aureliaGrid.slickGrid.scrollRowIntoView(row - 2);
        } else {
            this.aureliaGrid.slickGrid.scrollRowIntoView(0);
        }
    }

    defineGrid() {
        this.gridColumns = [
            // { id: 'week', name: 'Week', field: 'week', sortable: true },
            {
                id: 'day', name: 'Day', field: 'day', sortable: true,
                formatter: (row: number, cell: number, value: any, columnDef: any, dataContext: any, grid: any) => {
                    switch (value) {
                        case 1:
                            return 'Monday';

                        case 2:
                            return 'Tuesday';

                        case 3:
                            return 'Wednesday';

                        case 4:
                            return 'Thursday';

                        case 5:
                            return 'Friday';

                        case 6:
                            return 'Saturday';

                        case 7:
                            return 'Sunday';

                    }
                    return value;
                }
            },
            {
                id: 'one', name: '6AM', field: 'one', sortable: true,
                formatter: (row: number, cell: number, value: any, columnDef: any, dataContext: any, grid: any) => (value == null || value === undefined || value <= 0) ? '' : value,
                type: FieldType.number,
                editor: {
                    model: CustomFloatEditor,
                    placeholder: '0.00',
                    validator: CustomFloatValidator,
                    params: { decimalPlaces: 2, step: 0.25, minValue: 0, maxValue: 999 },
                },
                cssClass: 'cell-text-align-center'
            },
            {
                id: 'two', name: '8AM', field: 'two', sortable: true,
                formatter: (row: number, cell: number, value: any, columnDef: any, dataContext: any, grid: any) => (value == null || value === undefined || value == 0) ? '' : value,
                type: FieldType.number,
                editor: {
                    model: CustomFloatEditor,
                    placeholder: '0.00',
                    validator: CustomFloatValidator,
                    params: { decimalPlaces: 2, step: 0.25, minValue: 0, maxValue: 999 },
                },
                cssClass: 'cell-text-align-center'
            },
            {
                id: 'three', name: '12PM', field: 'three', sortable: true,
                formatter: (row: number, cell: number, value: any, columnDef: any, dataContext: any, grid: any) => (value == null || value === undefined || value <= 0) ? '' : value,
                type: FieldType.number,
                editor: {
                    model: CustomFloatEditor,
                    placeholder: '0.00',
                    validator: CustomFloatValidator,
                    params: { decimalPlaces: 2, step: 0.25, minValue: 0, maxValue: 999 },
                },
                cssClass: 'cell-text-align-center'
            },
            {
                id: 'four', name: '5PM', field: 'four', sortable: true,
                formatter: (row: number, cell: number, value: any, columnDef: any, dataContext: any, grid: any) => (value == null || value === undefined || value <= 0) ? '' : value,
                type: FieldType.number,
                editor: {
                    model: CustomFloatEditor,
                    placeholder: '0.00',
                    validator: CustomFloatValidator,
                    params: { decimalPlaces: 2, step: 0.25, minValue: 0, maxValue: 999 },
                },
                cssClass: 'cell-text-align-center'
            },
            {
                id: 'five', name: '8PM', field: 'five', sortable: true,
                formatter: (row: number, cell: number, value: any, columnDef: any, dataContext: any, grid: any) => (value == null || value === undefined || value <= 0) ? '' : value,
                type: FieldType.number,
                editor: {
                    model: CustomFloatEditor,
                    placeholder: '0.00',
                    validator: CustomFloatValidator,
                    params: { decimalPlaces: 2, step: 0.25, minValue: 0, maxValue: 999 },
                },
                cssClass: 'cell-text-align-center'
            },
        ];

        this.gridOptions = {
            autoResize: {
                containerId: 'container-grid',
                calculateAvailableSizeBy: 'window',
                bottomPadding: 65
            },
            enableGridMenu: false,
            enableCellNavigation: true,
            enableRowSelection: true,
            checkboxSelector: {
                hideSelectAllCheckbox: true
            },
            // rowSelectionOptions: {
            //     selectActiveRow: true,
            // },
            enableGrouping: true,
            editable: true,
            autoEdit: true,
            autoCommitEdit: true,
            editCommandHandler: async (item, column, editCommand) => {
                editCommand['item'] = item;
                this.commandQueue.push(editCommand);
                editCommand.execute();
                await this.repository.mergeMedicationScheduleComplex(this.patientMedication.guid, item.week, item.day, +item.one, +item.two, +item.three, +item.four, +item.five, this.authService.currentUser().user);
                // console.log(item, column, editCommand);

            },
        };
    }

    onCellValidation(e, args) {
        this.toastr.warning(args.validationResults.msg, 'Invalid numeric input');
    }

    async undo() {
        const command = this.commandQueue.pop();
        if (command && Slick.GlobalEditorLock.cancelCurrentEdit()) {
            await this.repository.mergeMedicationScheduleComplex(this.patientMedication.guid, command.item.week, command.item.day, +command.item.one, +command.item.two, +command.item.three, +command.item.four, +command.item.five, this.authService.currentUser().user);
            command.undo();
            this.aureliaGrid.slickGrid.gotoCell(command.row, command.cell, false);
        }
    }

    clearGrouping() {
        this.aureliaGrid.dataView.setGrouping([]);
    }

    collapseAllGroups() {
        this.aureliaGrid.dataView.collapseAllGroups();
    }

    expandAllGroups() {
        this.aureliaGrid.dataView.expandAllGroups();
    }

    setGrouping() {
        if (this.aureliaGrid) {
            this.aureliaGrid.dataView.setGrouping({
                getter: 'week',
                formatter: (g) => {
                    return `<strong>Week ${g.value}</strong>`;
                }
            });
        }
    }

}
