import { GenericEntitySearch } from '@resources/utils/generic-entity-search';
import { autoinject, Container, PLATFORM } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { Formatters, OnEventArgs, FieldType, Editors, FlatpickrOption, AureliaUtilService, OperatorType, Formatter, Column } from 'aurelia-slickgrid';
import { IEntity, ResultPager } from 'plugins/data-models';
import { AuthService } from 'services/auth-service';
import { ImpiloDb, ImpiloRepository } from 'services/repositories';
import { ValidationRules, Validator, ValidateEvent } from 'aurelia-validation';
import { CustomDateValidator, CustomFloatEditor, CustomFloatValidator } from '@resources/slickgrid/index';
import { ToastrService } from 'services/toastr-service';
import { isDate, toISOString } from '@resources/utils/other';
import { isGuid } from '@dts/scriptlib';

const medicationFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
    const html = `  <div>
                        <span>${value.description}</span>
                        <br/>
                        <span><small>${value.instructions}</small></span>
                    </div>`
    return html;
}

const dateFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
    if (isDate(value)) {
        return value.toString().substring(0, 10);
    }

    return value;
}

@autoinject()
export class Index extends GenericEntitySearch {
    private patient: Partial<ImpiloDb.Tables.Patient> = new ImpiloDb.Tables.Patient();

    private statuses: ImpiloDb.Tables.PatientMedicationStatus[];

    protected cancelled = false;
    protected notification: any;

    busyApply = false;

    isPharmacyAllowedTo: { isAllowed: boolean, problem: string, solution: string };

    constructor(private readonly repository: ImpiloRepository, private readonly router: Router, private readonly authService: AuthService, private readonly toastr: ToastrService, private readonly validator: Validator, private readonly aureliaUtilService: AureliaUtilService) {
        super();

        this.defineGrid2();

        this.repository.setMapping(ImpiloDb.ProcedureEnum.spgPatientMedication, ImpiloDb.Tables.PatientMedication.map);

        this.pager = new ResultPager(async (page: number, size: number) => {
            // const currentUser = this.authService.currentUser();

            const filter = this.filter.trim();

            if (filter.length >= 0) {
                this.isWaiting = true;
                const response = await this.repository.getPatientMedication(null, this.patient.guid);
                this.isWaiting = false;

                this.gridDataset = response.results.map((entity: ImpiloDb.Tables.PatientMedication) => {
                    (entity as any).status = entity.patientMedicationStatus.name; // for grouping purposes flatten the status
                    (entity as any).statusOrderBy = entity.patientMedicationStatus.orderBy; // for grouping purposes flatten the status
                    (entity.medication as any).instructions = entity.instructions?.toUpperCase();
                    (entity as any).dateWritten2 = toISOString(entity.dateWritten);
                    (entity as any).scriptValidTo2 = toISOString(entity.scriptValidTo);
                    return entity;
                });

                return response;
            }
        });

        this.pager.itemsPerPage = 200;
    }

    async attachedDone() {
        if (this.pager) {
            await this.pager.gotoFirst();
            // this.setGrouping();
        }
    }

    get canCreate() {
        this.cancelled = !this.isPharmacyAllowedTo.isAllowed;
        return this.isPharmacyAllowedTo.isAllowed;
    }

    navigateToAccount() {
        this.router.navigateToRoute('account');
    }

    async activate(params, routeConfig, navigationInstruction) {
        this.isPharmacyAllowedTo = await (this.repository as ImpiloRepository).isPharmacyAllowedTo('bulkUpdate');
        if (isGuid(params.guid)) {
            this.patient = await this.repository.getPatient(params.guid, false);
            const statuses = await (this.repository as ImpiloRepository).lookups().statuses();
            this.statuses = statuses.map(item => {
                item.value = item.name;
                item.label = item.name;
                return item;
            });
        }
    }


    beforeMerge(): Promise<boolean> {
        return Promise.resolve(true);
    }

    canMerge(entity): Promise<boolean> {
        return entity?.canMerge(this.validator);
    }

    async ok() {

        this.busyApply = true;

        for (const item of this.gridDataset) {
            if ((item as ImpiloDb.Tables.PatientMedication).isDirty()) {
                await item.merge(this.repository);
            }
        }

        this.busyApply = false;

        this.escape();
    }

    async apply() {

        this.busyApply = true;

        for (const item of this.gridDataset) {
            if ((item as ImpiloDb.Tables.PatientMedication).isDirty()) {
                await item.merge(this.repository);
            }
        }

        this.busyApply = false;
    }

    cancel() {
        this.cancelled = true;

        this.gridDataset.forEach(item => {
            item.cancel();
        });

        this.escape();
    }

    escape() {
        this.router.navigateToRoute('search');
        // this.router.navigateBack();
    }

    canDeactivate(): boolean {
        if (this.cancelled) {
            return true;
        }

        if (this.isDirty) {
            this.warning('Please save or undo the changes.');
            return false;
        }
        return true;
    }

    warning(msg) {
        const toastr = Container.instance.get(ToastrService);
        toastr.clear(this.notification);
        this.notification = toastr.warning(msg);
    }


    get isDirty(): boolean {
        if (this.gridDataset.length > 0) {

            let changed = false;

            for (const item of this.gridDataset) {
                if ((item as ImpiloDb.Tables.PatientMedication).isDirty()) {
                    changed = true;
                    break;
                }
            }

            return changed;
        }
        return false;

    }

    defineGrid() {
        // do the work in defineGrid2()
    }

    defineGrid2() {
        this.gridColumns = [
            {
                id: 'edit',
                field: 'edit',
                excludeFromColumnPicker: true,
                excludeFromGridMenu: true,
                excludeFromHeaderMenu: true,
                formatter: Formatters.editIcon,
                minWidth: 30,
                maxWidth: 30,
                onCellClick: async (e: Event, args: OnEventArgs) => {
                    const entity = args.dataContext as ImpiloDb.Tables.PatientMedication;
                    this.router.navigateToRoute('medication', { guid: entity.guid });
                },
            },
            { id: 'medication', name: 'Medication', field: '_medication', sortable: true, formatter: medicationFormatter },
            { id: 'doctor', name: 'Doctor', field: '_doctor.name', sortable: true, formatter: Formatters.complexObject, maxWidth: 200 },
            { id: 'idc10', name: 'ICD10', field: '_iCD10.code', sortable: true, formatter: Formatters.complexObject, maxWidth: 80 },
            {
                id: 'dateWritten', name: 'Date Written', field: 'dateWritten2', sortable: true, maxWidth: 120,
                editor: {
                    model: Editors.text,
                    validator: CustomDateValidator,
                },
                onCellChange: (e: Event, args: OnEventArgs) => {

                    // console.log(e, args);
                    const entity = args.dataContext as ImpiloDb.Tables.PatientMedication;

                    const dateWritten2 = (entity as any).dateWritten2;
                    const validFromDate = new Date(dateWritten2);

                    let validToDate = new Date(dateWritten2);
                    validToDate = new Date(validToDate.setMonth(validFromDate.getMonth() + entity.repeats));

                    (entity.dateWritten as any) = validFromDate.toUTCString();
                    (entity.scriptValidTo as any) = validToDate.toUTCString();

                    (entity as any).scriptValidTo2 = toISOString(validToDate);

                    if (isDate(entity.refillDate)) {
                        entity.refillDate = validToDate;
                    } else {
                        entity.refillDate = null;
                    }

                    this.aureliaGrid.gridService.updateItemById(entity.id, entity, { highlightRow: false });

                }
            },
            {
                id: 'repeats', name: 'Repeats', field: 'repeats', sortable: true, type: FieldType.number, editor: {
                    model: CustomFloatEditor,
                    placeholder: '0',
                    validator: CustomFloatValidator,
                    params: { decimalPlaces: 0, step: 1, minValue: 0, maxValue: 999 },
                },
                maxWidth: 80,
                cssClass: 'cell-text-align-center',
                onCellChange: (e: Event, args: OnEventArgs) => {

                    // console.log(e, args);
                    const entity = args.dataContext as ImpiloDb.Tables.PatientMedication;

                    const dateWritten2 = (entity as any).dateWritten2;
                    const validFromDate = new Date(dateWritten2);
                    let validToDate = new Date(dateWritten2);
                    validToDate = new Date(validToDate.setMonth(validFromDate.getMonth() + entity.repeats));

                    (entity.dateWritten as any) = validFromDate.toUTCString();
                    (entity.scriptValidTo as any) = validToDate.toUTCString();

                    (entity as any).scriptValidTo2 = toISOString(validToDate);

                    if (isDate(entity.refillDate)) {
                        entity.refillDate = validToDate;
                    } else {
                        entity.refillDate = null;
                    }

                    this.aureliaGrid.gridService.updateItemById(entity.id, entity, { highlightRow: false });

                }
            },
            {
                id: 'scriptValidTo', name: 'Script Expiry', field: 'scriptValidTo2', sortable: true, maxWidth: 120,
                editor: {
                    model: Editors.text,
                    validator: CustomDateValidator,
                },
                onCellChange: (e: Event, args: OnEventArgs) => {
                    const entity = args.dataContext as ImpiloDb.Tables.PatientMedication;
                    const scriptValidTo2 = (entity as any).scriptValidTo2;
                    entity.scriptValidTo = new Date(scriptValidTo2);
                }
            },
            {
                id: 'status', name: 'Status', field: 'status',
                formatter: Formatters.collectionEditor,
                type: FieldType.string,
                editor: {
                    model: Editors.singleSelect,
                    customStructure: { label: 'name', value: 'name' },
                    collectionAsync: new Promise<any>((resolve) => {
                        (this.repository as ImpiloRepository).lookups().statuses().then((statuses) => {
                            resolve(statuses);
                        });
                    }),
                },
                onCellChange: (e: Event, args: OnEventArgs) => {
                    const entity = args.dataContext as ImpiloDb.Tables.PatientMedication;
                    const idx = this.statuses.find(w => w.name == (entity as any).status);
                    entity.patientMedicationStatusGuid = idx.guid;

                },
                minWidth: 100
            },
        ];

        this.gridOptions = {
            autoResize: {
                containerId: 'container-grid',
                calculateAvailableSizeBy: 'window',
                bottomPadding: 65
            },
            enableGridMenu: false,
            enableCellNavigation: true,
            enableRowSelection: true,
            checkboxSelector: {
                hideSelectAllCheckbox: true
            },
            rowSelectionOptions: {
                selectActiveRow: true
            },
            editable: true,
            autoEdit: true,
            autoCommitEdit: true,
            rowHeight: 50,
        };
    }

    // abstract editEntity(entity: IEntity): void;
    editEntity(entity: IEntity) {
        // this.router.navigateToRoute('medication', { guid: entity.guid });
    }

    // abstract newEntity(): void;
    newEntity() {
        // this.router.navigateToRoute('medication', { guid: 'new' });
    }

    // abstract handleRowSelection(event, args);
    handleRowSelection(event, args) {
        // return patientMedicationSelectedRowAction(args.rows[0]);
    }

    onCellValidation(e, args) {
        this.toastr.warning(args.validationResults.msg, 'Invalid input');
    }

    onCellChanged(e, args) {
        console.log('onCellChange', args);
    }
}
