import { BindingEngine, Disposable } from 'aurelia-binding';
import { DialogController } from 'aurelia-dialog';
import { autoinject, computedFrom } from 'aurelia-framework';
import { BootstrapValidationController } from '@resources/renderers/bootstrap-form-renderer';
import { ImpiloRepository, ImpiloDb } from 'services/repositories';
import { ToastrService } from 'services/toastr-service';
import { Validator } from 'aurelia-validation';
import { GenericEntity } from '@resources/utils/generic-entity';

@autoinject()
export class DoctorDialog extends GenericEntity {

    model: {
        name: string
    };

    isWaiting = false;

    constructor(repository: ImpiloRepository, private readonly validationController: BootstrapValidationController, validator: Validator, private readonly bindingEngine: BindingEngine, private readonly dialogController: DialogController, private readonly toastr: ToastrService) {
        super(repository, validator);
        this.entity = new ImpiloDb.Tables.Doctor();
    }

    bind() {
        return this.validationController.validate();
    }

    beforeMerge() {
        const entity = (this.entity as ImpiloDb.Tables.Doctor);

        entity.pharmacyGuid = (this.repository as ImpiloRepository).pharmacyGuid();
        entity.created = new Date();
        entity.lastEdited = new Date();
        return super.beforeMerge();
    }

    afterMerge(): void {
        this.dialogController.ok(this.entity);
    }

    escape() {
        this.dialogController.cancel(this.model);
    }

    activate(model) {
        this.model = model;
        const entity = (this.entity as ImpiloDb.Tables.Doctor);
        entity.name = this.model.name;
    }

    deactivate() {
        //
    }

    @computedFrom('entity.name')
    get displayName() {
        return (this.entity as ImpiloDb.Tables.Doctor).name;
    }

}


