import { DialogController } from 'aurelia-dialog';
import { autoinject, computedFrom } from 'aurelia-framework';
import { ImpiloRepository, ImpiloDb } from 'services/repositories';
import { ToastrService } from 'services/toastr-service';
import { PrintService } from 'services/print-service';
import { ValidationRules } from 'aurelia-validation';
import { BootstrapValidationController } from '@resources/renderers/bootstrap-form-renderer';

@autoinject()
export class EMailDialog {
    model: {
        callback: (emailAddress: string, ccMe: boolean) => void,
        emailAddress: string,
        pharmacyEmail: string
    };

    title = "Email Order";
    emailAddress = '';
    pharmacyEmail = '';
    carbonCopyPharmacy = false;

    nameInput: HTMLElement;

    constructor(private readonly repository: ImpiloRepository, private readonly dialogController: DialogController, private readonly controller: BootstrapValidationController) {

        ValidationRules
            .ensure('emailAddress').required().email()
            .on(this);
    }

    async ok() {

        const validate = await this.controller.validate();
        if (validate.valid) {

            this.model.callback(this.emailAddress, this.carbonCopyPharmacy);

            this.dialogController.ok();
        } else {
            queueMicrotask(() => {
                this.nameInput.focus();
            });
        }
    }

    get canEmail(): boolean {
        return this.emailAddress?.length > 0;
    }

    cancel() {
        this.dialogController.cancel();
    }

    async activate(model) {
        this.model = model;
        this.emailAddress = model.emailAddress;
        this.pharmacyEmail = model.pharmacyEmail;
        const validate = await this.controller.validate();
    }

    deactivate() {
        //
    }
}


